/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const InfoIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M11.363 18h1.403v-7.277h-1.403zM12 8.746c.11 0 .219-.02.32-.062a.786.786 0 00.45-.45.821.821 0 00.062-.32.804.804 0 00-.5-.809.803.803 0 00-.332-.054.865.865 0 00-.332.043.82.82 0 00-.29.18.852.852 0 00-.206.601.905.905 0 00.055.336.859.859 0 00.453.473c.101.043.21.062.32.062zM12 24a11.375 11.375 0 01-4.656-.957A12.304 12.304 0 01.96 16.66a11.827 11.827 0 01-.957-4.668c0-1.601.324-3.191.957-4.664a12.273 12.273 0 012.582-3.816 12.149 12.149 0 013.8-2.57A11.554 11.554 0 0112 0c1.578-.012 3.14.293 4.598.895a11.854 11.854 0 013.894 2.601 12.336 12.336 0 012.57 3.828c.618 1.48.938 3.07.942 4.676 0 1.602-.313 3.191-.926 4.676a11.848 11.848 0 01-2.57 3.781 12.566 12.566 0 01-3.828 2.586c-1.477.64-3.07.969-4.68.957zm.031-1.402A10.187 10.187 0 0019.5 19.5a10.201 10.201 0 002.328-3.445c.535-1.293.797-2.688.77-4.086.02-1.39-.243-2.77-.774-4.055A10.217 10.217 0 0019.5 4.5c-.973-1-2.137-1.793-3.43-2.324a10.156 10.156 0 00-4.07-.77 10.181 10.181 0 00-4.07.77A10.223 10.223 0 004.5 4.5c-1 .973-1.79 2.14-2.324 3.43a10.236 10.236 0 00-.77 4.07 10.236 10.236 0 00.77 4.07A10.28 10.28 0 004.5 19.5a10.278 10.278 0 003.445 2.328c1.297.531 2.688.793 4.086.77zm0 0"
			fill="currentColor"
		/>
	</svg>
))
InfoIconSvg.displayName = 'InfoIconSvg'

const InfoIcon = forwardRef((props, ref) => <Icon component={InfoIconSvg} ref={ref} {...props} />)
InfoIcon.displayName = 'InfoIcon'

InfoIcon.defaultProps = {
	...Icon.defaultProps,
}
InfoIcon.propTypes = {
	...Icon.propTypes,
}

export default InfoIcon
export { InfoIconSvg }
